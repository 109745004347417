<template>
  <div class="page-box">
    <BaseSubTitle :title="shopLogisticsId?'修改物流模版':'新建物流模板'" />
    <a-spin :spinning="modalLoading">
      <a-form-model
        ref="formRef"
        :model="formData"
        @submit.native.prevent
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :rules="rules"
      >
        <a-form-model-item label="模版名称：" prop="name">
          <a-input style="width:300px" v-model="formData.name" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item label="计费方式：" prop="calcMethod">
          <a-radio-group v-model="formData.calcMethod">
            <a-radio :value="1">按件数</a-radio>
            <a-radio :value="2">按重量</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="配送区域">
          <a-table
            bordered
            :columns="modalColumns"
            :pagination="false"
            :data-source="modalTableData"
            :rowKey="(record,index) => index"
          >
            <template slot="regionCode" slot-scope="text,record,index">
              <a-tree-select
                style="cursor:pointer;width: 100%"
                :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                :replaceFields="replaceFields"
                v-model="record.regionCode"
                :treeData='onTreeFoucs(index)'
                tree-checkable
                :treeDefaultExpandedKeys="['0']"
                show-checked-strategy="SHOW_PARENT"
                placeholder="请选择配送区域"
              />
            </template>
            <template slot="firstNum" slot-scope="text,record">
              <a-input-number
                :min="0"
                :precision="formData.calcMethod===1?0:1"
                style="margin: -5px 0"
                v-model="record.firstNum"
                placeholder="请输入"
              />
            </template>
            <template slot="firstPrice" slot-scope="text,record">
              <a-input-number
                :min="0"
                :precision="2"
                style="margin: -5px 0"
                :value="text"
                placeholder="请输入"
                v-model="record.firstPrice"
              />
            </template>
            <template slot="addNum" slot-scope="text,record">
              <a-input-number
                :min="0"
                :precision="formData.calcMethod===1?0:1"
                style="margin: -5px 0"
                :value="text"
                placeholder="请输入"
                v-model="record.addNum"
              />
            </template>
            <template slot="addPrice" slot-scope="text,record">
              <a-input-number
                :min="0"
                :precision="2"
                style="margin: -5px 0"
                :value="text"
                placeholder="请输入"
                v-model="record.addPrice"
              />
            </template>
            <div class="table-operations" slot="operation" slot-scope="text,record,index">
              <a-popconfirm
                placement="topRight"
                title="确定要删除？"
                ok-text="确定"
                cancel-text="取消"
                @confirm="()=>confirmDeleteArea(index)"
              >
                <a-button type="link">删除</a-button>
              </a-popconfirm>
            </div>
          </a-table>
          <a-button style="margin-top:16px;" @click="addAreaTableData" type="primary">制定可配送区域和运费</a-button>
        </a-form-model-item>
      </a-form-model>
      <a-row type="flex" align="middle" justify="center">
        <a-button type="primary" size="large" @click="submit" :loading="saveBtnLoading">保存</a-button>
      </a-row>
    </a-spin>
  </div>
</template>
<script>
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { logistics, common } from '@/api'
import { reactive, toRefs, computed, onMounted } from '@vue/composition-api'
export default {
  name: 'PageTemplateForm',
  setup (props, { root }) {
    const columns1 = [
      {
        title: '可配送区域',
        dataIndex: 'regionCode',
        scopedSlots: { customRender: 'regionCode' }
      },
      {
        title: '首件（个）',
        dataIndex: 'firstNum',
        width: '13%',
        scopedSlots: { customRender: 'firstNum' }
      },
      {
        title: '运费（元）',
        dataIndex: 'firstPrice',
        width: '13%',
        scopedSlots: { customRender: 'firstPrice' }
      },
      {
        title: '续件（个）',
        dataIndex: 'addNum',
        width: '13%',
        scopedSlots: { customRender: 'addNum' }
      },
      {
        title: '续费（元）',
        dataIndex: 'addPrice',
        width: '13%',
        scopedSlots: { customRender: 'addPrice' }
      }
    ]
    const columns2 = [
      {
        title: '可配送区域',
        dataIndex: 'regionCode',
        scopedSlots: { customRender: 'regionCode' }
      },
      {
        title: '首重（kg）',
        dataIndex: 'firstNum',
        width: '13%',
        scopedSlots: { customRender: 'firstNum' }
      },
      {
        title: '运费（元）',
        dataIndex: 'firstPrice',
        width: '13%',
        scopedSlots: { customRender: 'firstPrice' }
      },
      {
        title: '续重（kg）',
        dataIndex: 'addNum',
        width: '13%',
        scopedSlots: { customRender: 'addNum' }
      },
      {
        title: '续费（元）',
        dataIndex: 'addPrice',
        width: '13%',
        scopedSlots: { customRender: 'addPrice' }
      }
    ]
    const modalColumns = computed(() => {
      if (state.formData.calcMethod === 1) {
        return columns1.concat({
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' }
        })
      } else {
        return columns2.concat({
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' }
        })
      }
    })
    const rules = {
      name: [
        { required: true, message: '请输入模版名称', trigger: 'change' },
        { max: 32, message: '您可以尝试一下简短的模版名称', trigger: 'change' }
      ]
    }
    const labelCol = { span: 2 }
    const wrapperCol = { span: 22 }
    const replaceFields = {
      children: 'children',
      title: 'regionName',
      key: 'regionCode',
      value: 'regionCode'
    }
    const state = reactive({
      modalLoading: false,
      formData: {
        calcMethod: 1
      },
      modalTableData: [],
      saveBtnLoading: false,
      formRef: null,
      regionOptions: [],
      shopLogisticsId: ''
    })
    onMounted(async () => {
      state.shopLogisticsId = root.$route.params.id
      await getRegionTree()
      if (state.shopLogisticsId) {
        getTemplateDetail()
      }
    })
    async function getTemplateDetail () {
      state.modalLoading = true
      let { code, data, msg } = await logistics.getTemplateDetail({
        shopLogisticsId: state.shopLogisticsId
      })
      state.modalLoading = false
      if (code === '00000') {
        state.formData = {
          calcMethod: data.calcMethod,
          name: data.name
        }
        if (data.children) {
          data.children.forEach(item => {
            if (item.regionCode) {
              item.regionCode = item.regionCode.split(',')
            }
          })
        }
        state.modalTableData = data.children
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function submit () {
      state.formRef.validate(async valid => {
        if (valid) {
          if (!state.modalTableData.length) {
            return root.$message.error('请添加配送区域')
          }
          if (
            state.modalTableData.filter(item => item.regionCode.length === 0)
              .length > 0
          ) {
            return root.$message.error('请选择地区')
          }
          let param = {
            shopId: getSession(SHOPID),
            ...state.formData,
            children: state.modalTableData.map((item, index) => {
              return {
                addNum: item.addNum,
                addPrice: item.addPrice,
                firstNum: item.firstNum,
                firstPrice: item.firstPrice,
                groupNum: index + 1,
                regionCode: item.regionCode.join(',')
              }
            })
          }
          if (state.shopLogisticsId) {
            param.shopLogisticsId = state.shopLogisticsId
          }
          state.saveBtnLoading = true
          let { code, msg } = await logistics.saveTemplate(param)
          state.saveBtnLoading = false
          if (code === '00000') {
            root.$bus.$emit('PageLogisticsList:refresh')
            root.$message.success(
              !state.shopLogisticsId ? '添加成功' : '修改成功'
            )
            if (
              root.$store.state.tabs.views
                .map(item => item.path)
                .indexOf('/shop/logistics') !== -1
            ) {
              root.$closeCurrentView()
            } else {
              root.$closeCurrentView('/shop/logistics')
            }
          } else {
            root.$message.error(msg || '添加失败')
          }
        }
      })
    }
    function addAreaTableData () {
      state.modalTableData.push({
        regionCode: [],
        firstNum: 1,
        firstPrice: 0,
        addNum: 1,
        addPrice: 0
      })
    }
    async function getRegionTree () {
      state.modalLoading = true
      let { data, msg, code } = await common.getRegionTree({ flag: true })
      state.modalLoading = false
      if (code === '00000') {
        state.regionOptions = data
      } else {
        root.$message.error(msg || '网络错误，请重试')
      }
    }
    function confirmDeleteArea (index) {
      state.modalTableData.splice(index, 1)
    }
    function deepClone (obj) {
      let objClone = Array.isArray(obj) ? [] : {}
      if (obj && typeof obj === 'object') {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            // 判断obj子元素是否为对象，如果是，递归复制
            if (obj[key] && typeof obj[key] === 'object') {
              objClone[key] = deepClone(obj[key])
            } else {
              // 如果不是，简单复制
              objClone[key] = obj[key]
            }
          }
        }
      }
      return objClone
    }
    function onTreeFoucs (index) {
      let arr = []
      state.modalTableData.forEach((item, _index) => {
        if (index !== _index) {
          item.regionCode.forEach(code => {
            if (code.length === 1) {
              arr.push({
                code,
                type: 'self'
              })
            } else if (code.length === 2) {
              arr.push({
                code,
                type: 'self'
              })
              arr.push({
                code: '0',
                type: 'parent'
              })
            } else if (code.length === 4) {
              arr.push({
                code,
                type: 'self'
              })
              arr.push({
                code: code.substr(0, 2),
                type: 'parent'
              })
              arr.push({
                code: '0',
                type: 'parent'
              })
            } else if (code.length === 6) {
              arr.push({
                code,
                type: 'self'
              })
              arr.push({
                code: code.substr(0, 2),
                type: 'parent'
              })
              arr.push({
                code: code.substr(0, 4),
                type: 'parent'
              })
              arr.push({
                code: '0',
                type: 'parent'
              })
            }
          })
        }
      })
      let regionOptions = deepClone(state.regionOptions)
      if (arr.length) {
        handleDisableTree(arr, regionOptions)
      }
      return regionOptions
    }
    function handleDisableTree (arr, treeData) {
      treeData.forEach(item => {
        arr.forEach(code => {
          if (code.code === item.regionCode && code.type === 'parent') {
            item.disableCheckbox = true
          }
          if (code.code === item.regionCode && code.type === 'self') {
            item.disabled = true
            setAllChildrenDisabled(item)
          }
        })
        if (item.children && item.children.length) {
          handleDisableTree(arr, item.children)
        }
      })
    }
    function setAllChildrenDisabled (obj) {
      obj.disabled = true
      if (obj.children && obj.children.length) {
        obj.children.forEach(item => {
          setAllChildrenDisabled(item)
        })
      }
    }
    return {
      rules,
      labelCol,
      wrapperCol,
      replaceFields,
      columns1,
      columns2,
      modalColumns,
      getRegionTree,
      confirmDeleteArea,
      ...toRefs(state),
      submit,
      addAreaTableData,
      getTemplateDetail,
      onTreeFoucs,
      handleDisableTree,
      setAllChildrenDisabled
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep
  .ant-select-selection--multiple
  .ant-select-search--inline
  .ant-select-search__field {
  display: none;
}
::v-deep .ant-select-selection--multiple {
  cursor: pointer;
}
</style>
